<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">




        <v-col v-if="viewPianificazione == 1" cols="6" class="mb-15">

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" to="/pianificazione">

                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/pianificazione_menu.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Planning</div>
                </template>
                <span>Planning</span>
            </v-tooltip>

        </v-col>



        <v-col v-if="viewReminder == 1" cols="6" class="mb-15">

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" to="/reminder">

                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/reminder_menu.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Reminder</div>
                </template>
                <span>Reminder</span>
            </v-tooltip>

        </v-col>

    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";


export default {
    name: "HelloWorld",

    mounted() {

        this.$root.$children[0].checkLogout();

        var info_global = JSON.parse(localStorage.getItem('td_info_global'));

        console.log("RESULT_DATA COOKIE: ", info_global);

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        console.log("A: ", a);
        console.log("B: ", b);

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        if (this.isSU == 0) {

            this.viewGruppoMail = this.getEnableConf("grp_mail_todolist");
            this.viewSlider = this.getEnableConf("slider_2dolist");
            this.viewConfInvioMail = this.getEnableConf("conf_invio_mail_2dolist");
            this.viewAttivita = this.getEnableConf("attivita_2dolist");
            this.viewPianificazione = this.getEnableConf("pianificazione_2dolist");
            this.viewProfilo = this.getEnableConf("profilo_2dolist");
            this.viewArgomenti = this.getEnableConf("argomenti_2dolist");
            this.viewReminder = this.getEnableConf("remind_2dolist");



        }
        else {

            this.viewGruppoMail = 1;
            this.viewSlider = 1;
            this.viewConfInvioMail = 1;
            this.viewAttivita = 1;
            this.viewPianificazione = 1;
            this.viewProfilo = 1;
            this.viewArgomenti = 1;
            this.viewReminder = 1;


        }

        this.loadLogo();

        this.getLastLogin();

    },

    methods: {

        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "2dolist")
                    .then((res) => {

                        console.log("res LoadLogoCompanyNew: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;




                    });
            } catch (error) {
                console.log("err LoadLogoCompanyNew", error);
            }

            console.log(response);



        },

        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "2dolist"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },



    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({

        isAdmin: 0,
        isSU: 0,

        viewGruppoMail: 0,
        viewSlider: 0,
        viewConfInvioMail: 0,
        viewAttivita: 0,
        viewPianificazione: 0,
        viewProfilo: 0,
        viewArgomenti: 0,
        viewReminder: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
